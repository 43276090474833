<template>
  <div class="container">
    <div class="order">
      <h2 v-if="!isLoading" class="order__header">
        Zamówienie nr {{ order.id }}
      </h2>

      <ImagesGrid v-if="!isLoading" :images="products" :has-toolbar="false" />
      <ImagesGridSkeleton v-if="isLoading" :fixed-layout="true" />

      <!-- TODO: Extract info-box into component -->
      <div v-if="!isLoading" class="order__download">
        <div class="info-box">
          <p v-if="isUnpaid" class="info-box__header">
            Zamówienie nie zostało jeszcze opłacone
          </p>
          <p v-if="isAwaiting" class="info-box__text">
            Płatność nie została jeszcze zaksięgowana
          </p>

          <p class="info-box__file-wrap">
            <a v-if="isPaid" :href="downloadUrl" class="info-box__cta">
              Pobierz wszystkie zdjęcia
            </a>
            <router-link
              v-if="isUnpaid"
              class="info-box__cta"
              :to="{
                name: 'checkout-payment',
                query: {
                  id: order.details.id,
                  amount: order.details.price,
                },
              }"
            >
              Zapłać {{ order.details.priceFormatted }}
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ImagesGrid from '../images-grid/images-grid.vue';
import ImagesGridSkeleton from '../images-grid/images-grid-skeleton.vue';
import getProductDownloadUrl from '@/vue/utils/getProductDownloadUrl';

export default {
  name: 'Order',

  components: {
    ImagesGrid,
    ImagesGridSkeleton,
  },

  data: () => ({
    isInitialized: false,
  }),

  computed: {
    ...mapGetters('user', {
      isLoadingOrders: 'isLoadingOrders',
      order: 'order',
    }),

    isLoading() {
      return !this.isInitialized || this.isLoadingOrders;
    },

    isPaid() {
      return this.order && this.order.details.payment === 'paid';
    },

    isUnpaid() {
      return (
        this.order &&
        this.order.details.payment === 'unpaid' &&
        this.order.details.status === 'incomplete'
      );
    },

    isAwaiting() {
      return this.order && this.order.details.status === 'processing';
    },

    orderId() {
      return this.$route.params.orderId;
    },

    products() {
      return this.isLoading ? [] : this.order.products;
    },

    downloadUrl() {
      return getProductDownloadUrl(this.order.id);
    },
  },

  async created() {
    await this.getOrder({ orderId: this.orderId });
    this.isInitialized = true;
  },

  methods: {
    ...mapActions('user', {
      getOrder: 'getOrder',
    }),
  },
};
</script>
